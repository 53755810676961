const content = [
    {
        id: 1,
        title: 'Grow Together',
        body: 'Generate meaningful discussions with your audience and build a strong, loyal community. Think of the insightful conversations you miss out on with a feedback form.',
        image: 'illustration-grow-together.svg',
    },
    {
        id: 2,
        title: 'Flowing Conversations',
        body: "You wouldn't paginate a conversation in real life, so why do it online? Our threads have just-in-time loading for a more natural flow.",
        image: 'illustration-flowing-conversation.svg',
    },
    {
        id: 3,
        title: 'Your Users',
        body: "It takes no time at all to integrate Huddle with your app's authentication solution. This means, once signed in to your app, your users can start chatting immediately.",
        image: 'illustration-your-users.svg',
    },
    {
        id: 4,
        title: 'lorem ipsum',
        body: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud consequat.",
        image: 'illustration-grow-together.svg',
    }
]

export default content